import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

// Styles
import { Section, Carousel, Circle } from './style'
import WithoutComplicationsJSON from '../../assets/data/WithoutComplications.json'
import { SuperAppInterProps } from '../../types'

type WithoutComplicationsProps = {
  title: string;
  description: string;
  image: string;
}

const WithoutComplications = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)
  const url = 'https://inter-co.onelink.me/Qyu7/lxeo4p4f'

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 pr-md-0'>
            <h2 className='fs-20 fs-md-24 fs-lg-28 fs-xl-48 lh-29 lh-md-34 lh-xl-52 text-grayscale--500 text-md-center fw-500'>
              Aqui não tem complicação, você abre uma conta para menor em poucos minutos:
            </h2>
          </div>
        </div>
        <div className='row'>
          <Carousel className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 4 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
            >
              { WithoutComplicationsJSON.map((item: WithoutComplicationsProps, index: number) => (
                <div key={item.title}>
                  <div className='d-flex flex-md-column'>
                    {windowWidth < WIDTH_MD && <span className='fs-24 lh-28 text-grayscale--500 fw-500 mr-2'>{index + 1} -</span>}
                    {windowWidth >= WIDTH_MD &&
                      <Circle className='d-flex justify-content-center align-items-center'>
                        <span className='text-white fw-500'>{index + 1}</span>
                      </Circle> }
                    <p className='fs-24 lh-28 text-grayscale--500 fw-500 text-md-center title'>
                      {item.title}
                    </p>
                  </div>
                  <p className='fs-16 lh-20 text-grayscale--400 text-md-center description mt-1'>{item.description}</p>
                  <div className='d-flex justify-content-center mt-4 mt-md-5 mt-lg-4'>
                    <ImageWebp
                      pathSrc={item.image}
                      arrayNumbers={[ 230, 170, 173, 224 ]}
                      altDescription='Telas de celular mostrando o passo a passo para abertura de conta para menor no Inter'
                      className='mb-4 mb-md-0'
                    />
                  </div>
                </div>
            ))}
            </DefaultCarousel>
            <div className='w-100 d-flex justify-content-center'>
              {windowWidth < WIDTH_MD
              ? (
                <a
                  href={url}
                  title='Abrir conta gratuita'
                  className='btn btn--lg bg-orange--base text-white text-none mt-4'
                  onClick={() => {
                    setDataLayer(dataLayer)
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      section_name: 'Aqui não tem complicação, você abre uma conta para menor em poucos minutos',
                      element_action: 'click button',
                      element_name: 'Abrir conta gratuita',
                      redirect_url: url,
                    })
                  }}
                >
                  Abrir conta gratuita
                </a>)
              : (
                <button
                  title='Abrir conta gratuita'
                  className='btn btn--lg bg-orange--base text-white text-none mt-md-2'
                  onClick={() => {
                    setOpenModal && setOpenModal(true)
                    setDataLayer(dataLayer)
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      section_name: 'Aqui não tem complicação, você abre uma conta para menor em poucos minutos',
                      element_action: 'click button',
                      element_name: 'Abrir conta gratuita',
                    })
                  }}
                >
                  Abrir conta gratuita
                </button>
              )
            }
            </div>
          </Carousel>
        </div>
      </div>
    </Section>
  )
}

export default WithoutComplications
