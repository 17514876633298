import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
// Styles
import { Wrapper } from './style'

import pageContext from './pageContext.json'
import Hero from './sections/hero/_index'
import KidsAccount from './sections/kids-account/_index'
import FinancialIndependence from './sections/financial-independence/_index'
import AccountForMinors from './sections/account-for-minors/_index'
import WithoutComplications from './sections/without-complications/_index'
import AllAboutAccounts from './sections/all-about-accounts/_index'
import useDomReady from 'src/hooks/window/useDomReady'
import QrCode18anos from './assets/image/qr-code-18-anos.png'

const ForYourBusiness = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Guardar dinheiro é simples!',
    element_action: 'click button',
    element_name: 'Guardar meu dinheiro',
  })

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block">Abra agora a conta do seu filho!'
        instructions='Ainda não tem conta no Inter? Aponte a câmera do celular para o QR Code, baixe o app e abra sua conta gratuita.'
        qrCode={QrCode18anos}
        dataLayer={dataLayer}
        isHrTop
        isHrBottom
      />
    </Modal>
  )

  return (
    <Wrapper>
      {openModal}
      <Layout pageContext={pageContext}>
        <Hero />
        <KidsAccount />
        <FinancialIndependence />
        <AccountForMinors />
        <WithoutComplications setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
        <AllAboutAccounts />
      </Layout>
    </Wrapper>
  )
}

export default ForYourBusiness
